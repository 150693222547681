.scene {
  font-size: 1px;
  width: 100%;
  background-image: url("~/public/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.scene .area {
  width: 100%;
}

.scene .area td {
  width: 1em;
  height: 1em;
  min-height: 1em;
  min-width: 1em;
  aspect-ratio: 1;
}

.scene .sprite img, .scene .sprite {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  aspect-ratio: 1;
  margin: 0;
  padding: 0;
  text-align: center;
}

.scene .sprite img.flipWidth {
  transform: scaleX(-1);
}

.scene .sprite img.flipHeight {
  transform: scaleY(-1);
}

.scene .sprite {
  position: relative;
  top: 0;
  left: 0;
}

.scene .sprite.next {
  margin-top: -100%;
}

.scene table tr, .scene table td {
  margin: 0;
  padding: 0;
}
