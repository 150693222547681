.controls {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  opacity: 20%;
}

.controls .movement {
  width: 20%;

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.controls .movement .row {
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

.controls .movement .row .button {
  width: 33%;
}

.controls .actions {
  width: 10%;
}

.controls .button {

}

.controls .button img {
  width:100%;
  height:100%;
  object-fit:cover;
}
